import React from "react";
import { useTranslation } from "react-i18next";
import PartyHostGuestForm from "../../components/Party/PartyHostGuestForm";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

const AddGuest = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const editData = location.state?.rowData;
    const addGuestMutation = ApiHook.CallAddGuest();
    const updateGuestMutation = ApiHook.CallUpdateGuest();
    const addGuest = (data, clearForm) => {
        addGuestMutation.mutateAsync(data, {
            onSuccess: (res) => {
                if (res.status) {
                    toast.success(t(res.data));
                    clearForm();
                } else {
                    toast.error(t(res.data));
                }
            }
        })
    }
    const updateGuest = (data) => {
        updateGuestMutation.mutateAsync(data, {
            onSuccess: (res) => {
                if (res.status) {
                    toast.success(t(res.data));
                } else {
                    toast.error(t(res.data));
                }
            }
        })
    }
    return (
        <>
            <div className="page_head_top">{t("addGuest")}</div>
            {/* content here */}
            <PartyHostGuestForm
                type={editData ? "updateGuest" : "addGuest"}
                backBtnPath="/guest-management"
                submitFunction={editData ? updateGuest : addGuest}
                editData={editData}
            />
        </>
    )
};

export default AddGuest;