import React, { useState } from 'react';
import Wizard from '../MyPartyPortal/PartyWizard.jsx';
import PartySelection from './PartySelection.jsx';
import PartyGuests from './PartyGuests.jsx';
import PartyOrders from './PartyOrders.jsx';
import PartyTransition from '../../../utils/partyAnimation.js'
import { ApiHook } from '../../../hooks/apiHook.js';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const PartyPortal = (props) => {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [activeCard, setActiveCard] = useState(null);
    // const [selectedParty, setSelectedParty] = useState(null);
    const currency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );

    // ---------------------------------- API's -------------------------------------------- //
    const allParties = ApiHook.CallAllParties();
    const details = ApiHook.CallPartyDetails(props.selectedParty?.value);

    const handleSelectChange = async (selectedOption) => {
        props.setSelectedParty(selectedOption);
        localStorage.setItem('partyId', selectedOption.value);
    };

    const handleNext = () => {
        if (props.selectedParty === null) {
            toast.error(t('selectAParty'));
            return
        }
        if (step < 3) {
            PartyTransition('next', step, setStep);
        }
    };

    const handleBack = () => {
        if (step > 1) {
            PartyTransition('back', step, setStep);
        }
    };

    const handleCardClick = (card) => {
        setActiveCard(card);
    };
    return (
        <div className="main-content-regsiter partySelectionVIew" style={{ maxWidth: '100%' }}>
            <Wizard step={step} />
            {step === 1 &&
                <div className={`step-1 d-block`}>
                    {/* <div className={`step-1 ${step === 1 ? 'd-block' : 'd-none'}`}> */}
                    <PartySelection
                        onNext={handleNext}
                        onCardClick={handleCardClick}
                        handleSelectChange={handleSelectChange}
                        selectedParty={props.selectedParty}
                        setSelectedParty={props.setSelectedParty}
                        activeCard={activeCard}
                        parties={allParties?.data}
                        details={details?.data}
                    />
                </div>
            }
            {step === 2 &&
                <div className={`step-2 d-block`}>
                    {/* <div className={`step-2 ${step === 2 ? 'd-block' : 'd-none'}`}> */}
                    <PartyGuests
                        onNext={handleNext}
                        onBack={handleBack}
                        selectedParty={props.selectedParty}
                        guests={details?.data?.guests}
                        isClosed={details?.data?.partyStatus}
                    />
                </div>
            }
            {step === 3 &&
                <div className={`step-3 d-block`}>
                    {/* <div className={`step-3 ${step === 3 ? 'd-block' : 'd-none'}`}> */}
                    <PartyOrders
                        data={details?.data}
                        selectedParty={props.selectedParty}
                        onBack={handleBack}
                        currency={currency}
                        conversionFactor={conversionFactor}
                        isClosed={details?.data?.partyStatus}
                    />
                </div>
            }
        </div>
    );
};

export default PartyPortal;
