import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SetupPartyStep from './../../examples/setupParty.json'
import CreateParty from "../../components/Party/setup-party/CreateParty";
import SelectHost from "../../components/Party/setup-party/SelectHost";
import SelectPartyTime from "../../components/Party/setup-party/SelectPartyTime"
import SelectAddress from "../../components/Party/setup-party/SelectAddress";
import { useForm } from "react-hook-form";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const SetupParty = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [activeStep, setActiveStep] = useState(SetupPartyStep.data[activeStepIndex]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [file, setFile] = useState('');
    const { register, reset, setValue, setError, watch, clearErrors, trigger, formState: { errors } } = useForm({
        file: "",
        partyName: "",
        hostType: "", // new || existing || self
        hostData: {},
        fromDate: "",
        fromTime: "",
        toTime: "",
        toDate: "",
        addressType: "",
        addressData: {}
    });
    const formValues = watch();
    // api
    const setupPartyMutation = ApiHook.CallSetupParty();
    const timeValidate = ApiHook.CallTimeValidation();
    // handlers
    const nextStep = async () => {
        let isValid = await validateCurrentStep();

        // validate time api
        if(activeStep.step === 3) {
            let data = {
                fromDate: formValues.fromDate,
                fromTime: formValues.fromTime,
                toDate: formValues.toDate,
                toTime: formValues.toTime
            }
            
            const result = await timeValidate.mutateAsync(data);
            
            if(result.status === true) {
                isValid = true;
            } else {
                isValid = false;
                toast.error(t(result.description));
            }
        }
        if (isValid) {
            if (activeStepIndex < SetupPartyStep.data.length - 1) {
                const newIndex = activeStepIndex + 1;
                setActiveStepIndex(newIndex);
                setActiveStep(SetupPartyStep.data[newIndex]);
            }
        }
    }
    const prevStep = () => {
        if (activeStepIndex > 0) {
            const newIndex = activeStepIndex - 1;
            setActiveStepIndex(newIndex);
            setActiveStep(SetupPartyStep.data[newIndex]);
        }
    }
    // phone validation
    const isPhoneValid = (phone) => {
        return phone?.length >= 7 && !isNaN(phone);
    };
    const isPhoneNumberValid = isPhoneValid(formValues?.hostData?.phone);
    // form validation
    const validateCurrentStep = async () => {
        let isValid = true;

        switch (activeStep.label) {
            case "partyNameAndImage":
                isValid = await trigger(["partyName"]);
                // if (!formValues.file) {
                //     setError("file", { type: "required", message: "this_field_is_required" });
                //     isValid = false;
                // }
                break;
            case "selectTheHost":
                if (!formValues.hostType?.value) {
                    setError("hostType", { type: "required", message: "this_field_is_required" });
                    isValid = false;
                }
                if (formValues.hostType?.value === "new") {
                    if (!formValues.hostData.phone) {
                        setError("hostData.phone", { type: "required", message: "this_field_is_required" });
                        isValid = false;
                    }
                    if (!isPhoneNumberValid) {
                        isValid = false;
                        setError("hostData.phone", {
                            message: ("min_length"),
                        });
                    }
                    isValid = await trigger([
                        "hostData.name",
                        "hostData.secondName",
                        "hostData.address",
                        "hostData.countryId",
                        "hostData.stateId",
                        "hostData.city",
                        "hostData.email",
                        "hostData.zip"
                    ]);
                }
                break;
            case "selectPartyTime":
                ["fromDate", "fromTime", "toDate", "toTime"].forEach(field => {
                    if (!formValues[field]) {
                        setError(field, {
                            type: "required",
                            message: t("this_field_is_required")
                        });
                        isValid = false;
                    }
                });
                break;
            case "selectTheAddress":
                if (!formValues.addressType?.value) {
                    setError("addressType", { type: "required", message: "this_field_is_required" });
                    isValid = false;
                }
                if (formValues.addressType?.value === "newAddress") {
                    if (!formValues.addressData.phone) {
                        setError("addressData.phone", { type: "required", message: "this_field_is_required" });
                        isValid = false;
                    }
                    if (!isPhoneNumberValid) {
                        isValid = false;
                        setError("addressData.phone", {
                            message: ("min_length"),
                        });
                    }
                    isValid = await trigger([
                        "addressData.name",
                        "addressData.secondName",
                        "addressData.address",
                        "addressData.countryId",
                        "addressData.stateId",
                        "addressData.city",
                        "addressData.email",
                        "addressData.zip"
                    ]);
                }
                break;
            default:
                isValid = true;
        }

        return isValid;
    };
    const completePartySetup = () => {
        let data = formValues
        const hostType = data.hostType?.value
        const addressType = data.addressType?.value
        const hostCountryId = data.hostData?.countryId?.value
        const addressCountryId = data.addressData?.countryId?.value
        const payload = {
            ...data,
            hostType: hostType,
            addressType:addressType,
            hostData: {
                ...data.hostData,
                countryId: hostCountryId
            },
            addressData:{
                ...data.addressData,
                countryId: addressCountryId 
            },
            type: "party"
        }
        setupPartyMutation.mutate(payload, {
            onSuccess: (res) => {
                if (res.status) {
                    toast.success(t(res.data));
                    navigate('/my-party-portal')
                } else {
                    toast.error(t(res.data.description))
                }
            }
        })
    }
    // console.log(errors, "errors");
    return (
        <>
            <div className="page_head_top">{t("setupParty")}</div>
            {/* container */}
            <div className="container">
                {/* main content */}
                <div className="register_row align-items-center justify-content-center">
                    <div className="main-content-regsiter">
                        <div className="row justify-content-center pt-0 p-4" id="wizardRow">
                            <div className="col-md-12 text-center">
                                <h2>{t('setupParty')}</h2>
                                <div className="wizard-form py-4 my-2 mt-3">
                                    <ul id="progressBar" className="progressbar px-lg-5 px-0 mb-2">
                                        {SetupPartyStep.data.map((step, index) => (
                                            <li key={index} id="progressList-1"
                                                className={`d-inline-block w-20 position-relative text-center float-start progressbar-list ${step.step <= activeStep.step ? 'active' : ''}`}>
                                                {t('step')}{step.step}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {activeStep.label === "partyNameAndImage" &&
                            <CreateParty
                                register={register}
                                errors={errors} trigger={trigger}
                                setError={setError}
                                setValue={setValue}
                                selectedImage={selectedImage}
                                setSelectedImage={setSelectedImage}
                                setFile={setFile}
                            />
                        }
                        {activeStep.label === "selectTheHost" &&
                            <SelectHost
                                formValues={formValues}
                                setValue={setValue}
                                errors={errors}
                                clearErrors={clearErrors}
                                register={register}
                                trigger={trigger}
                            />
                        }
                        {activeStep.label === "selectPartyTime" &&
                            <SelectPartyTime
                                formValues={formValues}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                errors={errors}
                            />
                        }
                        {activeStep.label === "selectTheAddress" &&
                            <SelectAddress
                                formValues={formValues}
                                setValue={setValue}
                                errors={errors}
                                clearErrors={clearErrors}
                                register={register}
                                trigger={trigger}
                            />
                        }
                        {activeStep.label === "completeParty" &&
                            <div className="row row_top justify-content-center form-business">
                                <div className="col-lg-12 col-md-12" id="payment">
                                    <div className="regsiter_step_1_view_left_sec_head">{t('completeParty')}</div>
                                    <p>{t('completePartyPrompt')}</p>
                                </div>
                            </div>
                        }
                        {/* Bottom navigation */}
                        <div className="col-md-12">
                            {activeStep.label !== "partyNameAndImage" &&
                                <button type="button"
                                    className="btn btn-dark text-white float-start back mt-4 rounded-3"
                                    onClick={prevStep}>{t('back')}
                                </button>
                            }
                            {activeStep.label !== "completeParty" &&
                                <button type="button"
                                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                                    onClick={nextStep}>
                                    {t('next')}
                                </button>
                            }
                            {activeStep.label === "completeParty" &&
                                <button type="button"
                                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                                    onClick={completePartySetup}>
                                    {t('setupMyParty')}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default SetupParty;