import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import Select from "react-select";
import { useQueryClient } from "@tanstack/react-query";
import WAValidator from "multicoin-address-validator";

const PaymentDetailsTab = ({ payment }) => {
  console.log("🚀 ~ PaymentDetailsTab ~ payment:", payment?.blockchain)
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [paymentMethodType, setPaymentMethodType] = useState({ slug: payment?.slug ?? "confirmo", id: payment?.id ?? 9 });
  const [paymentValue, setPaymentValue] = useState(payment?.value);
  const [confirmoAddress, setConfirmoAddress] = useState(payment?.blockchain);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (payment?.slug !== undefined) {
      setPaymentMethodType({ slug: payment.slug, id: payment.id });
    }
    if (payment?.value !== undefined) {
      setPaymentValue(payment.value);
    }


  }, [payment]);
  const paymentMutation = ApiHook.CallPaymentDetails();
  const getStripeAccountId = ApiHook.CallGetStripeAccountId();

  const paymentMethods = (data) => {
    return data?.map((item) => ({
      label: item?.name,
      value: item?.slug,
      id: item?.id,
    }));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPaymentValue(value);
    setConfirmoAddress(value);

    var valid = WAValidator.validate(value, 'Tron');
    if (valid){
      console.log('This is a valid address');
      setError("");
    }
    else
    setError('INVALID Tron (TRC-20) Address');

    // setError(""); // Clear any previous errors
  };

  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
    setError(""); // Clear any previous errors
  };

  const changePaymentMethod = (item) => {
    setPaymentMethodType({ slug: item?.value, id: item.id });
    setPaymentValue(""); // Clear the payment value when changing payment method
    setError(""); // Clear any previous errors
  };
  const linkSubmit = () => {
    setLoading(true);
    getStripeAccountId.mutateAsync(null, {
      onSuccess: (res) => {
        if (res.status) {
          setLoading(false);
          window.location.href = res?.data?.accountUrl;
        } else {
          setLoading(false);
          toast.error(t("errorOccuredPleaseTryAgain"));
        }
      },
      onError: (res) => {
        setLoading(false);
        toast.error(t("errorOccuredPleaseTryAgain"));
      },
    });
  };

  const onSubmit = () => {
    if (!paymentValue && (paymentMethodType.slug === "paypal")) {
      setError("this_field_is_required");
      return;
    }
    if (!paymentValue && (paymentMethodType.slug === "stripe")) {
      setError("updateStripe");
      return;
    }
    if (!paymentValue && (paymentMethodType.slug === "confirmo")) {
      setError("updateWalletAddress");
      return;
    }

    let data = {
      paymentMethod: paymentMethodType.id,
      ...(paymentMethodType.slug === "paypal" && { paypalAccount: paymentValue }),
      ...(paymentMethodType.slug === "stripe" && { stripeAccount: paymentValue }),
      ...(paymentMethodType.slug === "confirmo" && { walletAddress: paymentValue }),
    };
    paymentMutation.mutate(data, {
      onSuccess: (res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["profile"] });
          toast.success(t(res?.data));
          setIsEditModeEnabled(false);
        } else if (res.code) {
          toast.error(t(res?.description));
        } else if (res.message) {
          toast.error(t(res?.message));
        }
      },
      onError: (err) => {
        setError("Failed to update payment details. Please try again.");
      },
    });
  };

  return (
    <div id="fourthTab" className="tabcontent">
      <div className="editSec">
        <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i className="fa-solid fa-pen-to-square" style={{ color: "#32009c" }}></i>
          </span>
        </div>
      </div>
      <h3>{t("paymentDetails")}</h3>
      <div className="tabcontent_form_section">
        {paymentMethodType.slug === "stripe" && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("stripeAccount")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <div style={{ display: "flex" }}>
                <input
                  name="stripeAccount"
                  type="text"
                  className="form-control"
                  disabled={!isEditModeEnabled && true}
                  onChange={handleChange}
                  value={payment?.value ? payment?.value : ""}
                />
                <button
                  className="linkStrip_btn"
                  onClick={linkSubmit}
                  type="submit"
                >
                  {" "}
                  {loading ? (
                    <>
                      {"Linking to Stripe"}{" "}
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{
                          marginRight: "5px",
                          Color: "transparent"
                        }}
                      />
                    </>
                  ) : (
                    "Link to Stripe"
                  )}
                </button>
              </div>
              {error && <p className="text-danger">{t(error)}</p>}
            </div>
          </div>
        )}
        {paymentMethodType.slug === "paypal" && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("paypalAccount")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="paypalAccount"
                type="text"
                className="form-control"
                value={paymentValue}
                disabled={!isEditModeEnabled}
                onChange={handleChange}
              />
              {error && <p className="text-danger">{t(error)}</p>}
            </div>
          </div>
        )}
        <div className="mb-3 row tabBlockClass">
          <label className="col-sm-3 col-form-label labelWidthClass">
            {t("paymentMethod")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <Select
              value={paymentMethods(payment?.options)?.find(
                (item) => item.value === paymentMethodType.slug
              )}
              options={paymentMethods(payment?.options)}
              isDisabled={!isEditModeEnabled}
              onChange={changePaymentMethod}
            />
          </div>
        </div>
        {paymentMethodType.slug === "confirmo" && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              Wallet Address (TRC-20) :
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="wallletAddress"
                type="text"
                className="form-control"
                // value={paymentValue}
                value={confirmoAddress}
                disabled={!isEditModeEnabled}
                onChange={handleChange}
              />
              {error && <p className="text-danger">{t(error)}</p>}
            </div>
          </div>
        )}
        <div className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}>
          <SubmitButton
            id={"1"}
            isSubmitting={!isEditModeEnabled || paymentMethodType.slug === "stripe"}
            click={onSubmit}
            text="update"
            className="btn"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsTab;