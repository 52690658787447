import axios from "axios";
import API from "../../api/api";
import { BASE_URL, DEFAULT_KEY } from "../../config/config";

const callApi = async (endpoint, method = 'get', body = null) => {
    try {
        let response;

        switch (method.toLowerCase()) {
            case 'get':
                response = await API.get(endpoint);
                break;
            case 'post':
                response = await API.post(endpoint, body);
                break;
            case 'delete':
                response = await API.delete(endpoint, { data: JSON.stringify(body) });
                break;
            case 'patch':
                response = await API.patch(endpoint, body);
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }
        return response?.data;

    } catch (error) {
        console.log(error);
        throw error;
    }
};
export const partyServices = {
    setupParty: async (file, data) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("partyData", JSON.stringify(data));
        // Create a new Axios instance for this specific request
        const customAxios = axios.create({
            baseURL: BASE_URL,
        });
        // Copy the api-key and access-token headers from the API instance to customAxios
        customAxios.defaults.headers.common["api-key"] =
            localStorage.getItem("api-key") || DEFAULT_KEY;
        customAxios.defaults.headers.common["access-token"] =
            localStorage.getItem("access-token") || "";
        // Set the "Content-Type" header to "multipart/form-data"
        customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        try {
            const response = await customAxios.post(
                `add-party?type=party`,
                formData
            );
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    },
    addHost: (body) => callApi("manage-host", 'post', body),
    updateHost: (body) => callApi("manage-host", 'post', body),
    addGuest: (body) => callApi("manage-guest", 'post', body),
    updateGuest: (body) => callApi("manage-guest", 'post', body),
    getPartyGuests: (perPage, page) => callApi(`party-guests?perPage=${perPage}&page=${page}`, 'get'),
    getPartyHosts: (perPage, page) => callApi(`party-hosts?perPage=${perPage}&page=${page}`, 'get'),
    getAllParties: () => callApi("all-parties", 'get'),
    getPartyDetails: (id) => callApi(`get-party?partyId=${id}`, 'get'),
    inviteGuests: (body) => callApi("invite-guest", 'post', body),
    getGuestOrders: (partyId, perPage, page) => callApi(`get-guest-orders?partyId=${partyId}&page=${page}&perPage=${perPage}`, 'get'),
    getPartyProducts: (partyId, guestId) => callApi(`party-products?partyId=${partyId}&guestId=${guestId}`),
    deleteGuestOrder: (body) => callApi("delete-guest-order", 'delete', body),
    createGuestOrder: (body) => callApi("create-guest-order", 'post', body),
    getPartyGuestsById: (id) => callApi(`get-guests-by-party?partyId=${id}`, 'get'),
    patchprocessOrders: (body) => callApi('process-guest-order', 'patch', body),
    closeParty: (body) => callApi(`end-party`, 'patch', body),
    addToPartyCart: (body) => callApi(`create-guest-order`, 'post', body),
    timeValidation:(body) => callApi(`/validate-party-timings?fromDate=${body.fromDate}&fromTime=${body.fromTime}&toDate=${body.toDate}&toTime=${body.toTime}`)
};